// Purpose: Menu configuration

export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Speisekarte',
    path: '/menue',
  },
  {
    title: 'Impressum',
    path: '/impressum',
  },
];