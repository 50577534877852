import { Box, MenuItem } from "@mui/material";
import "./styles/MenuList.css";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { menu } from "../configs/menu";

type MenuListProps = {
  viewType?: string;
  menuClose?: () => void;
};

type MenuListItemProps = {
  itemText: string;
  itemLink: string;
  menuClose?: () => void;
};

const MenuListItem = (props: MenuListItemProps) => {
  const navigateTo = useNavigate();
  const theme = useTheme();
  return (
    <MenuItem
      className="menuItem"
      onClick={() => {
        navigateTo(props.itemLink);
        window.scrollTo(0, 0);
        props.menuClose?.();
      }}
      color={theme.palette.primary.main}
      style={{ background: "transparent" }}
    >
      {props.itemText}
    </MenuItem>
  );
};

const MenuList = (props: MenuListProps) => {
  const theme = useTheme();

  return (
    <Box
      className={
        props.viewType === "columnView"
          ? "menuList  menuItemList columnView"
          : "menuList  menuItemList rowView"
      }
    >
      {menu.map((item, index) => (
        <MenuListItem
          key={index}
          itemText={item.title}
          itemLink={item.path}
          menuClose={props.menuClose}
        />
      ))}
    </Box>
  );
};

export default MenuList;
