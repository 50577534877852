import React from "react";
import Banner from "../components/Banner";
import { Box, Button, Grid, Icon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../images/green.png";
import Header from "../components/Header";
import Phone from "@mui/icons-material/Phone";
import { sign } from "crypto";
export interface HomeProps {}

const ComingSoonScreen: React.FC<HomeProps> = (props: any) => {
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.light,
        fontSize: "2rem",
        margin: "0",
        height: "100%",
      }}
      className="container"
    >
      <Grid
        item
        className="banner headline"
        style={{
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
          fontSize: "2rem",
        }}
      >
        <Banner headline="COMING SOON" body="Mini-Golf Stüberl" />
      </Grid>

      <Grid
        item
        className="banner center"
        style={{
          background: theme.palette.primary.light,
          opacity: 0.65,
          marginBottom: 0,
        }}
        padding={0}
      >
        <img src={logo} loading="lazy" width={"18%"} />
      </Grid>
      <Grid
        item
        className="content item"
        textAlign="end"
        style={{
          background: theme.palette.background.paper,
          opacity: 0.8,
          fontSize: "1.6rem",
        }}
      >
        <h1>Mini-Golf Stüberl</h1>
        <p>Breslauer Str. 121 84028 Landshut Tel.: 0871 / 52238</p>
      </Grid>
    </Grid>
  );
};

export default ComingSoonScreen;
