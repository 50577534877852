import React from "react";
import "./styles/Banner.css";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

interface BannerProps {
  headline?: string;
  body?: string;
  content?: JSX.Element;
  justifyContent?: string;
}

const Banner = (props: BannerProps): JSX.Element => {
  const theme = useTheme();

  const headline = props.headline || "";
  const body = props.body || "";
  const content = props.content || "";

  return (
    <Box
      className="banner"
      justifyContent={props.justifyContent || "center"}
      style={{
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.light,
      }}
    >
      <h2>{headline}</h2>
      <p>{body}</p>
      <div>{content}</div>
    </Box>
  );
};

export default Banner;
