import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import HomeScreen from "./screens/Home";
import MenueScreen from "./screens/Menue";
import ImpressumScreen from "./screens/Impressum";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { green, yellow, grey, lime } from "@mui/material/colors";
import Footer from "./components/Footer";
import ComingSoonScreen from "./screens/ComingSoonScreen";
import { Box, Grid, Icon, IconButton } from "@mui/material";
import { Facebook, Instagram, Phone } from "@mui/icons-material";
import NavBar from "./components/NavBar";

const theme = createTheme({
  palette: {
    primary: {
      main: green[900],
      light: yellow["A700"],
      dark: grey[800],
    },
    secondary: {
      main: yellow[50],
      light: yellow[50],
      dark: green[900],
    },
    info: {
      main: yellow["A700"],
      light: green[900],
      dark: grey[800],
    },
    background: {
      paper: grey[900],
      default: grey[800],
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: green[900],
          },

          item: {
            '&[role="menu"]': {
              with: "100%",
              backgroundColor: yellow[900],
            },
          },
          '&[role="menuitem"]': {
            justifyContent: "center",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const App: React.FC = (props) => {
  const them = useTheme();

  const isLive = false;

  return (
    <ThemeProvider theme={theme || them}>
      <BrowserRouter>
        <NavBar />
        <Header />
        {/*        
        <>
          <div
            className="App"
            {...props}
            style={{ background: theme.palette.background.paper }}
          >
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/menue" element={<MenueScreen />} />
              <Route path="/impressum" element={<ImpressumScreen />} />
            </Routes>
          </div>
          <Footer />
        </> */}

        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.light,
            fontSize: "2rem",
            userSelect: "none",
          }}
        >
          <ComingSoonScreen />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
