import React from "react";
import "./styles/Header.css";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Header: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid container className="App-header">
      <Grid
        item
        className="BannerImg"
        minHeight={{ xs: "55vh", md: "77vh" }}
        marginTop={{ xs: "66px", md: "55px" }}
        marginBottom={{ xs: "-33px", md: "-1px" }}
      >
        <h1
          className="App-title"
          style={{ color: theme.palette.primary.light, userSelect: "none" }}
        >
          Wilkommen im Stüberl
        </h1>
      </Grid>
    </Grid>
  );
};

export default Header;
