import { useEffect, useState } from "react";
import "./styles/NavBar.css";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { AppBar, Box, Grid, IconButton, Toolbar } from "@mui/material";
import { Clear, Facebook, Instagram, Phone } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../images/yellow.png";
import MenuList from "./MenuList";

const NavBar = () => {
  const theme = useTheme();
  const mobileMenuId = "primary-menu-mobile";

  const [scrolled, setScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const listenScrollEvent = () => {
    if (window.scrollY < 73) {
      return setScrolled(false);
    } else if (window.scrollY > 70) {
      return setScrolled(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const SideMenu = () => {
    return (
      <Grid>
        <Menu
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          color={theme.palette.primary.light}
          variant="selectedMenu"
        >
          <MenuItem className="menuItem" onClick={handleClose}>
            <IconButton
              size="small"
              color="inherit"
              aria-label="menu"
              aria-controls="primary-menu-mobile"
              aria-haspopup="true"
              edge="start"
              sx={{ width: "100%" }}
              onClick={handleClose}
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Clear className="clearIcon" />
            </IconButton>
          </MenuItem>
          <MenuList viewType="rowView" menuClose={handleClose} />
        </Menu>
      </Grid>
    );
  };

  return (
    <AppBar
      className="navigation"
      position="fixed"
      style={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="flex-end"
        flexDirection="column"
        style={{
          padding: 0,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid
          item
          height="3rem"
          xs={12}
          className={scrolled ? "contact hide" : "contact show"}
        >
          <IconButton
            size="small"
            color="info"
            aria-label="Phone"
            aria-controls="primary-menu-mobile"
            aria-haspopup="true"
            edge="end"
            sx={{ width: "100%" }}
            onClick={() => window.open("tel:+4987152238")}
            title="Anrufen"
            style={{
              justifyContent: "flex-end",
              padding: "0.8rem",
              flexDirection: "row",
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Phone />
            <p>Tel.:0871/52238</p>
          </IconButton>
        </Grid>
        <Grid
          item
          height="3rem"
          xs={12}
          className={scrolled ? "contact show" : "contact hide"}
          style={{ position: "absolute" }}
        >
          <IconButton
            size="small"
            color="info"
            aria-label="Phone"
            aria-controls="primary-menu-mobile"
            aria-haspopup="true"
            edge="end"
            sx={{ width: "100%" }}
            onClick={() => window.open("https://www.instagram.com")}
            title="Anrufen"
            style={{
              justifyContent: "flex-end",
              padding: "0.8rem",
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Facebook style={{ paddingRight: "1rem" }} />
            <Instagram style={{ paddingLeft: "1rem" }} />
          </IconButton>
        </Grid>
      </Grid>

      <img
        src={logo}
        className={scrolled ? "App-logo big" : "App-logo small"}
      />
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Toolbar variant="dense">
          <IconButton
            className="menuIcon"
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <SideMenu />
        </Toolbar>
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <MenuList viewType="columnView" />
      </Box>
    </AppBar>
  );
};

export default NavBar;
